import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Component as ProgressState } from './ProgressState';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "progress-state"
    }}>{`Progress State`}</h1>
    <p>{`진행 과정과 현재 상태를 보여주기 유용한 컴포넌트입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={ProgressState} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<ProgressState\n  value=\"2\"\n  items={[\n    {\n      label: \'Lorem\',\n      value: \'1\',\n    },\n    {\n      label: \'Ipsum\',\n      value: \'2\',\n    },\n    {\n      label: \'dolor\',\n      value: \'3\',\n    },\n    {\n      label: \'sit\',\n      value: \'4\',\n    },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ProgressState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ProgressState value="2" items={[{
        label: 'Lorem',
        value: '1'
      }, {
        label: 'Ipsum',
        value: '2'
      }, {
        label: 'dolor',
        value: '3'
      }, {
        label: 'sit',
        value: '4'
      }]} mdxType="ProgressState" />
    </Playground>
    <h2 {...{
      "id": "custom-example"
    }}>{`Custom example`}</h2>
    <Playground __position={2} __code={'<ProgressState\n  value=\"inProgress\"\n  items={[\n    {\n      label: \'준비\',\n      value: \'ready\',\n    },\n    {\n      label: \'진행\',\n      value: \'inProgress\',\n    },\n    {\n      label: \'완료\',\n      value: \'success\',\n    },\n  ]}\n  color=\"gray\"\n  backgroundColor=\"pink\"\n  activeColor=\"yellow\"\n  activeBackgroundColor=\"red\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ProgressState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ProgressState value="inProgress" items={[{
        label: '준비',
        value: 'ready'
      }, {
        label: '진행',
        value: 'inProgress'
      }, {
        label: '완료',
        value: 'success'
      }]} color="gray" backgroundColor="pink" activeColor="yellow" activeBackgroundColor="red" mdxType="ProgressState" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      